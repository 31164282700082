<template>
  <div class="report">
    <Header back="返回" title="汇报管理" index="首页" titleOne="汇报管理" beforeTitle="新增/编辑" />
    <div class="content">
      <el-form
        :label-position="labelPosition"
        :model="reportform"
        :rules="reportRule"
        ref="reportRule"
        label-width="80px"
      >
        <el-select v-model="reportform.type" placeholder="请选择汇报类型">
          <el-option label="日报" value="1">日报</el-option>
          <el-option label="周报" value="2">周报</el-option>
          <el-option label="月报" value="3">月报</el-option>
        </el-select>
        <h1>{{ reportform.title }}</h1>
        <el-form-item label="今天完成的工作">
          <el-input type="textarea" v-model="reportform.completed_work"></el-input>
        </el-form-item>
        <el-form-item label="未完成的工作">
          <el-input type="textarea" v-model="reportform.incomplete_work"></el-input>
        </el-form-item>
        <el-form-item label="明天的计划">
          <el-input type="textarea" v-model="reportform.plan"></el-input>
        </el-form-item>
        <el-form-item label="工作心得">
          <el-input type="textarea" v-model="reportform.experience"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="reportform.remark"></el-input>
        </el-form-item>
        <h2>抄送给</h2>
        <el-select v-model="reportform.organization_id" @change="getccid" placeholder="请选择部门">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="reportform.cc_id" placeholder="抄送人">
          <el-option
            v-for="item in moptions"
            :key="item.id"
            :label="item.true_name"
            :value="item.id"
          ></el-option>
        </el-select>
        <div class="foot_TJ">
          <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      isDisable: false,
      typeStr: ["日报", "周报", "月报"],
      labelPosition: "top",
      reportform: {
        completed_work: "",
        incomplete_work: "",
        plan: "",
        experience: "",
        remark: "",
        organization_id: "",
        cc_id: "",
        type: "",
        title: "",
      },
      reportRule: {
        completed_work: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        incomplete_work: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        plan: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        experience: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
      },
      options: [],
      moptions: [],
      value: "",
      input: "",
      trustname: "",
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  created () {
    this.getccid()
  },
  watch: {
    "reportform.organization_id": {
      handler (val, oval) {
        this.getccid(val);
      },
      deep: true,
    },
  },
  mounted () {
    this.getitemId();
    if (this.meber_id) {
      this.axios
        .get("/api/report/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          console.log(res.data.type.toString());
          res.data.type = res.data.type.toString();
          this.reportform = res.data;
          this.reportform.id = this.meber_id;
        });
    }
  },
  methods: {
    onSubmit () {
      this.$refs.reportRule.validate((vaild) => {
        if (vaild && this.reportform.type && this.reportform.cc_id) {
          this.btnLoading = true
          this.axios
            .post("/api/report/store", this.reportform)
            .then((res) => {
              this.$message({
                type: 'success',
                message: res.message
              })
              this.$router.push("/report/index");
              this.isDisable = true;
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.warning("请填写完整汇报类型和抄送人");
        }
      });
    },
    getitemId () {
      this.axios
        .get("/api/organization/select_organization")
        .then((res) => {
          this.options = res.data;
        });
    },
    getccid (id) {
      console.log(id);
      this.axios
        .get("/api/member/select_member", { params: { organization_id: id } })
        .then((res) => {
          console.log(res, 9999)
          this.moptions = res.data;
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
  },
};
</script>

<style scoped="scoped">
.report h1 {
  font-size: 20px;
  font-weight: bold;
}

.report h2 {
  font-size: 17px;
  font-weight: bold;
  color: #000000;
}

.el-select {
  margin-top: 20px;
  margin-right: 10px;
}
</style>
